import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import HubspotForm from 'react-hubspot-form'
import Seo from '../components/seo';

export const query = graphql`
    query($id: ID!) {
        wpcontent {
            whitepaper(id: $id) {
                title
                uri
                content
                excerpt
                whitepaperCoverImage
                whitepaperSlide1
                whitepaperSlide2
                whitepaperSlide3
                hsFormId
                seo {
                    metaDesc
                    canonical
                    title
                    opengraphTitle
                    opengraphDescription
                    opengraphType
                    twitterTitle
                    twitterDescription
                }
            }
        }
    }
`

const WhitepaperTemplate = ({ data }) => {
    // console.log(data)
    const whitepaper = data.wpcontent.whitepaper
    const metaDesc = data.wpcontent.whitepaper.seo.metaDesc
    const pageTitle = data.wpcontent.whitepaper.seo.title
    let uri = data.wpcontent.whitepaper?.uri
    
    let baseUrl = "https://www.vantagecircle.com";

    let pageUri = baseUrl + uri
    // const pageType = data.wpcontent.whitepaper.seo.opengraphType

    // let pageurl = `https://www.vantagecircle.com`
    // const uri = whitepaper.uri
    // if(uri){
    //     pageurl+= `${uri}`
    // }
     
    return (
        <Layout>
            {/* <Seo title={pageTitle}
            description={metaDesc}
            meta={[
                {
                  name: `description`,
                  content: metaDesc,
                },
                {
                  property: `og:title`,
                  content: pageTitle,
                },
                {
                  property: `og:description`,
                  content: metaDesc,
                },
                {
                  name: `twitter:title`,
                  content: pageTitle,
                },
                {
                  name: `twitter:description`,
                  content: metaDesc,
                },
              ]}
              link={[
                  {
                      rel: `canonical`,
                      href: pageUri,
                  },
              ]}
            /> */}

            <Seo title={pageTitle}
                description={metaDesc}
                link={[
					{
						rel: `canonical`,
						href: pageUri,
					},
				]}
            />

            <section className="slanted-bg bg-purple-500 mx-auto py-10 pb-0 sm:py-20 sm:pb-20 relative">
                <div className="container max-w-5xl sm:text-center px-6 sm:px-6 lg:px-6">
                    <div className="site-breadcrumb sm:text-center mb-5 text-white opacity-80">
                        <Link className="text-purple-50 hover:underline hover:text-white transition duration-500 ease-in-out" to="/">Home</Link>&nbsp;&gt;&gt;&nbsp;
                        <Link className="text-purple-50 hover:underline hover:text-white transition duration-500 ease-in-out" to="/hr-academy/whitepapers/">Whitepapers</Link>&nbsp;&gt;&gt;&nbsp; 
                        <span dangerouslySetInnerHTML={{ __html: whitepaper.title }} />
                    </div>
                    <h1 className="hero-title-2-white" dangerouslySetInnerHTML={{ __html: whitepaper.title }} />
                    <p className="section-subtitle-white" dangerouslySetInnerHTML={{ __html: whitepaper.excerpt }} />
                    <div className="mt-5 sm:mt-8 md:mt-10 flex justify-center">
                        <div className="">
                            <a href="#download-now" className="vc-white-btn">
                                Download now
                            </a>
                        </div>
                    </div>           
                </div>
                <img className="absolute z-1 hero-iconsl1 hero-icons-top" src="https://res.cloudinary.com/vantagecircle/image/upload/w_200/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-L1.png" alt="Guides and Ebooks" />
                <img className="absolute z-1 hero-iconsl2 hero-icons-bottom hero-icons-1" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-L2.png" alt="Guides and Ebooks" />
                <img className="absolute z-1 hero-iconsr1 hero-icons-top" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-R1.png" alt="Guides and Ebooks" />
                <img className="absolute z-1 hero-iconsr2 hero-icons-bottom hero-icons-1" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-R2.png.png" alt="Guides and Ebooks" />
            </section>
            {/* <section className="container max-w-5xl sm:text-center px-6 sm:px-6 lg:px-6 lg:-mt-72">
                <div className="ebbok-featured-image">
                    <img className="mx-auto" src={whitepaper.ebookCoverImage} alt={whitepaper.title} />
                </div>
            </section> */}

            <section className="ebook-carousel container text-center max-w-4xl mt-10 md:-mt-8 px-7">
                <CarouselProvider className="relative mx-auto"
                    naturalSlideWidth={110}
                    naturalSlideHeight={80}
                    totalSlides={3}
                >
                    <Slider className="">
                        <Slide index={0}><img src={whitepaper.whitepaperSlide1} alt="image1"/></Slide>
                        <Slide index={1}><img src={whitepaper.whitepaperSlide2} alt="image2"/></Slide>
                        <Slide index={2}><img src={whitepaper.whitepaperSlide3} alt="image3"/></Slide>
                    </Slider>
                    <ButtonBack className="shadow eb-carousel-btn">
                        <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
                    </ButtonBack>
                    <ButtonNext className="shadow eb-carousel-btn">
                        <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
                    </ButtonNext>
                    <DotGroup />
                </CarouselProvider>
            </section>
            <section className="container max-w-7xl py-10 md:py-20 mx-auto px-6">
                <div id="download-now">
                    <div className="md:flex">
                        <div className="card-1 w-full md:w-1/2 mb-10 md:mb-0 md:mt-20">
                            <h2 className="mb-5 sm:mb-10 text-3xl font-semibold text-gray-900 sm:text-4xl">You will get to learn</h2>
                            <ul className="form-bullets list-inside orangebullets" dangerouslySetInnerHTML={{ __html: whitepaper.content }} />
                        </div>
                        <div className="card-1 w-full md:w-1/2 text-gray-900 rounded shadow-lg p-8 lg:p-10 md:ml-28">
                            <div className="form-section">
                                <h2 className="mt-2 mb-8 text-2xl font-semibold text-gray-900 sm:text-3xl">Fill up to download your copy now</h2>
                                <HubspotForm
                                    portalId='6153052'
                                    formId={whitepaper.hsFormId}
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />
                                {/* <div className="notetext text-center font-normal text-sm">We safeguard your personal information in accordance with our <a href="/privacy-policy/" rel="noopener noreferrer" target="_blank">Privacy Policy</a></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="bottom-cta m-6 md:m-0 relative z-1">
                <div className="container max-w-5xl bg-purple-300 rounded-lg shadow-xl p-10 md:p-14 relative z-9 overflow-hidden md:-bottom-32">
                    <div  className="bottom-cta-content text-center text-white">
                        <div className="section-title-white">Find Out How Vantage Circle Works</div>
                        <div className="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                            <div>
                                <a className="vc-ghost-btn-cta-banner" href="/request-demo/">Schedule a Free Demo</a>
                            </div>
                        </div>
                    </div>
                    <img loading="lazy" className="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" className="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section> */}
        </Layout>
    )
}

export default WhitepaperTemplate

